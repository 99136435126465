import { isOfType } from 'app/utils/helpers/type.helpers'

import Alert from './Alert'

export type ErrorHandlerType = {
  _base?: string
  message?: string
  errors?:
    | Record<string, string>
    | {
        _base?: string
        base?: string
        listings_changes?: string
        shipping_type?: string
        listing_id?: string
        image?: string
        _shipping_restrictions?: string[]
      }
}

export const isErrorHandlerType = (
  error: unknown
): error is { data: ErrorHandlerType } =>
  !!error && isOfType.object(error) && 'data' in error

class ErrorHandler extends Error {
  constructor(data?: ErrorHandlerType, showAlert = true) {
    const stripeError = data?.message

    const baseError =
      data?._base ||
      data?.errors?._base ||
      data?.errors?.base ||
      data?.errors?.listings_changes ||
      data?.errors?.shipping_type ||
      data?.errors?.listing_id ||
      data?.errors?.image ||
      stripeError

    super(baseError)
    this.name = 'ErrorHandler'

    if (showAlert) {
      Alert.error(baseError || 'Something went wrong')
    }
  }
}

export default ErrorHandler
